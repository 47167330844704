<template>
  <div class="approve">
    <div class="approve-top">
      <div class="tea-search">
        <el-form ref="form" :inline="true" class="demo-form-inline">
          <!-- 兑换物品 -->
          <el-form-item label="物品兑换:" v-show="tab_pane_index=='second'">
            <el-select v-model="search_data.wpid" placeholder="请选择" clearable @change="handelSearch">
              <el-option :value="item.id" :label="item.mc" v-for="(item,index) in goods_options" :key='index'></el-option>
            </el-select>
          </el-form-item>
          <!-- 审核结果 -->
          <el-form-item label="审核结果:" v-show="tab_pane_index=='second'">
            <el-select v-model="search_data.shzt" placeholder="请选择" @change="handelSearch">
              <el-option :value="1" label="同意兑换" :key="1"></el-option>
              <el-option :value="2" label="暂缓兑换" :key="2"> </el-option>
              <el-option :value="3" label="不同意兑换" :key="3"> </el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item>
            <div class="search-text" style="width:300px">
              <el-input placeholder="请输入兑换物品或学生名称查询" v-model="search_data.mc" class="input-with-select">
              </el-input>
            </div>
          </el-form-item>
          <!-- 查询按钮 -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearch">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <!-- tab切换 -->
      <el-tabs type="border-card" v-model="tab_pane_index" @tab-click='tabClick()'>
        <el-tab-pane label="待审核" name="first"></el-tab-pane>
        <el-tab-pane label="审核记录" name="second"></el-tab-pane>
      </el-tabs>
      <!-- tableData -->
      <el-table stripe border :data="tableData" height="calc(100% - 90px)" @sort-change="changeTableSort">
        <el-table-column label="序号" type="index" align="center" :index="indexMethod" width="80" />
        <el-table-column prop="wpmc" label="兑换物品" align="center" width="200" />
        <el-table-column prop="sl" label="兑换数量" align="center" width="100" />
        <el-table-column prop="dhrq" label="兑换申请时间" align="center" sortable>
          <template v-slot="scope">
            <span>{{new Date(scope.row.dhrq).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="xsmc" label="学生姓名" align="center" width="150" />
        <el-table-column prop="bh" label="物品编号" align="center" />
        <!-- --物品余量 物品所需金币-- -->
        <el-table-column prop="wpyl" label="物品余量" align="center" width="150" v-if="tab_pane_index == 'first'" />
        <el-table-column prop="wpjb" label="物品所需金币" align="center" width="150" v-if="tab_pane_index == 'first'" />
        <!--  审核结果 审核时间 备注-->
        <el-table-column prop="shzt" label="审核结果" align="center" width="150" v-if="tab_pane_index == 'second'">
          <template v-slot="scope">
            <el-tag type="success" v-show="scope.row.shzt==1">同意兑换</el-tag>
            <el-tag type="warning" v-show="scope.row.shzt==2">暂缓兑换</el-tag>
            <el-tag type="danger" v-show="scope.row.shzt==3">拒绝兑换</el-tag>
            <el-tag type="info" v-show="scope.row.shzt==0">待审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="spsj" label="审核时间" align="center" v-if="tab_pane_index == 'second'">
          <template v-slot="scope">
            <span>{{new Date(scope.row.spsj).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="bz" label="备注" align="center" v-if="tab_pane_index == 'second'" /> -->
        <el-table-column label="操作" align="center" width="150px">
          <template v-slot="scope">
            <el-button v-show="tab_pane_index=='first'" size="mini" icon="el-icon-edit" type="primary" class="fl tea-btn" @click="toExamine(scope.row,1)">审核</el-button>
            <el-button v-show="tab_pane_index=='second'" size="mini" icon="el-icon-edit" type="primary" class="fl tea-btn" @click="toExamine(scope.row,2)" :disabled='scope.row.shzt!=2'>修改审核结果</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tea-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
        </el-pagination>
      </div>
    </div>
    <!-- 审核 -->
    <el-dialog v-model="examine_obj.dialogVisible" :title='examine_obj.title' :modal-append-to-body="true" width="700px" class="tea-diolag">
      <div class="examine-box">
        <el-form ref="form" label-width="100px">
          <!-- 审核结果 -->
          <el-form-item label="审核结果:" aria-placeholder="请选择审核结果" required>
            <el-select v-model="examine_obj.model.shzt" style="width:200px" placeholder="请选择" @change="handelSearch">
              <el-option :value="1" label="同意兑换" :key="1"></el-option>
              <el-option :value="2" label="暂缓兑换" :key="2"> </el-option>
              <el-option :value="3" label="不同意兑换" :key="3"> </el-option>
            </el-select>
          </el-form-item>
          <!-- 修改原因 -->
          <!-- <el-form-item label="修改原因:" aria-placeholder="请填写" v-if="examine_obj.type==2" required>
            <el-input type="textarea" style="width:500px" :autosize="{ minRows: 3, maxRows: 4}" placeholder="请输入内容" v-model="examine_obj.model.spbz" show-word-limit resize='none'></el-input>
          </el-form-item> -->
          <!-- 备注 -->
          <el-form-item label="备注:" aria-placeholder="请填写" :required='examine_obj.type==2'>
            <el-input type="textarea" style="width:500px" :autosize="{ minRows: 3, maxRows: 4}" placeholder="请输入内容" v-model="examine_obj.model.spbz" show-word-limit resize='none'></el-input>
          </el-form-item>
          <!--  -->
        </el-form>
        <!-- 历史审核记录 -->
        <div class="examine-record" v-show="examine_obj.type==2">
          <div class="title">审核记录</div>
          <!-- height="calc(100% - 10px)" -->
          <el-table stripe border :data="examine_obj.tableData" height="280px">
            <el-table-column prop="xgrq" label="审核时间" align="center">
              <template v-slot="scope">
                <span>{{new Date(scope.row.xgrq).Format("yyyy-MM-dd")}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="xm" label="审核人" align="center" />
            <el-table-column prop="shzt" label="审核结果" align="center">
              <template v-slot="scope">
                <el-tag type="success" v-show="scope.row.shzt==1">同意兑换</el-tag>
                <el-tag type="warning" v-show="scope.row.shzt==2">暂缓兑换</el-tag>
                <el-tag type="danger" v-show="scope.row.shzt==3">拒绝兑换</el-tag>
                <el-tag type="info" v-show="scope.row.shzt==0">待审核</el-tag>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="personCount" label="修改原因" align="center" /> -->
            <el-table-column prop="bz" label="备注" align="center" />
          </el-table>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="examine_obj.dialogVisible=false">取 消</el-button>
          <el-button type="primary" @click="examineSubmit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 修改审核结果-->
  </div>
</template>
<script>
import {
  exchangeApproveListApi,//审核列表
  exchangeGoodsOptionsApi,//物品下拉
  exchangeApproveSaveApi,//
} from '@/api/teacher'
import {
  selectStudentCoin,//审核列表
} from '@/api/student'
export default {
  name: 'approve',
  components: {
  },
  data() {
    return {
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      tab_pane_index: 'first',
      search_data: {
        shzt: 0,
        // dhjd:0,
        mc: '',
        // wpid:75,
        zhid: this.$store.state.userInfo.zhid,
      },
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      goods_options: [],
      tableData: [],
      // obj
      // 审核的弹窗
      examine_obj: {
        dialogVisible: false,
        title: '审核',
        type: 1,//1审核 2修改审核结果
        model: {
          id: null,
          shzt: 1,
          spbz: '',
        },
        tableData: [],
      },
      // 修改审核记录的弹窗
    }
  },
  watch: {
  },
  created() {
    this.getGoodsOptions()

  },
  computed: {
  },
  mounted() {
    this.getExchangeApproveList()
  },
  methods: {
    // 获取下拉框选项
    getGoodsOptions() {
      exchangeGoodsOptionsApi({}).then(res => {
        if (res.result) {
          this.goods_options = res.result
        }
      })
    },
    // 切换常规班级临时班级
    tabClick() {
      this.search_data.wpid = null;
      this.search_data.mc = null;
      this.search_data.dhrqpx = null
      if (this.tab_pane_index == 'first') {
        // 待审核
        this.search_data.shzt = 0
      } else if (this.tab_pane_index == 'second') {
        this.search_data.shzt = 1
        // 审核记录
      }
      this.tableData = []
      this.handelSearch()
    },
    // 查询审批列表
    getExchangeApproveList() {
      const data = this.search_data;
      data.currentPage = this.pages.currentPage;
      data.pageSize = this.pages.pageSize;
      data.yycdid = sessionStorage.getItem('yycdid')
      // if (this.tab_pane_index == 'first') {
      //   // 待审核

      // } else if (this.tab_pane_index == 'second') {
      //   // 审核记录
      //   // data.ywjx = 0
      // }
      exchangeApproveListApi(data).then(res => {
        if (res.result) {
          this.pages.total = res.result.total
          this.tableData = res.result.list
        }
      })
    },
    // 计算序号
    indexMethod(index) {
      return (this.pages.currentPage - 1) * this.pages.pageSize + index + 1;
    },
    // 排序
    changeTableSort(column) {
      if (column.prop == "dhrq") {
        console.log(column.order)
        // 兑换申请时间
        if (column.order == 'ascending') {
          // 升序
          this.search_data.dhrqpx = 1
        } else if (column.order == 'descending') {
          // 降序
          this.search_data.dhrqpx = 2
        }
      }
      this.handelSearch()
    },
    // ------------------------------------------------------分页器
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.handelSearch()
    },
    //  -----------------------------------------------------分页器
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getExchangeApproveList()
    },
    // 点击查询
    handelSearch() {
      // 点击查询，页数，每页显示个数，初始化
      this.pages.currentPage = 1;
      this.getExchangeApproveList()
    },
    // 修改审核结果
    toExamine(result, val) {
      console.log(result)
      this.examine_obj.type = val;
      this.examine_obj.model.xsid = result.xsid;
      this.examine_obj.model.xsmc = result.xsmc;
      this.examine_obj.model.id = result.id;
      this.examine_obj.model.spbz = '';
      console.log(result)
      if (val == 1) {
        // 审核
        this.examine_obj.title = '审核'
      } else if (val == 2) {
        // 修改审核结果
        this.examine_obj.title = '修改审核结果'
        this.examine_obj.tableData = result.listLogs;
        this.examine_obj.model.cjr = result.cjr
      }
      this.examine_obj.dialogVisible = true
    },
    // /审核提交
    examineSubmit() {
      if (this.examine_obj.type == 2 && (!this.examine_obj.model.spbz)) {
        this.$message.error('请填写备注')
        return false
      }
      this.$confirm('确定提交该审批吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = this.examine_obj.model;
        if (this.examine_obj.type == 1) {
          // data.cjr = this.yhid;
          data.sprid = this.yhid
          data.xgr = this.yhid
        } else if (this.examine_obj.type == 2) {
          data.xgr = this.yhid
          data.sprid = this.yhid
        }
        data.yycdid = sessionStorage.getItem('yycdid')
        data.yycdmc = '魔都图谱英语'
        data.yylx = 2
        // data.zhid = this.zhid;
        data.sprxm = JSON.parse(sessionStorage.getItem('userInfo')).realname
        selectStudentCoin({ xsid: this.examine_obj.model.xsid }).then((ressec) => {
          if (ressec.success) {
            data.jbzs = ressec.result.wdjb
            exchangeApproveSaveApi(data).then(res => {
              if (res.result) {
                if (res.success) {
                  this.$message.success('审核已提交')
                  this.examine_obj.dialogVisible = false;
                  this.getExchangeApproveList()
                }
              } else {
                this.$message.error(res.resultDesc)
              }
            })
          } else {
            this.$message.error(ressec.resultDesc)
          }
        })

      })
    },
    // 
  }
}
</script>
<style lang="scss" scoped>
.approve {
  height: 100%;
  .table {
    height: calc(100% - 100px);
    .tea-pagination {
      margin-top: 15px;
    }
    .el-tabs--border-card {
      border: 1px solid #ebeef5;
      box-shadow: none;
      border-bottom: none;
      /deep/.el-tabs__header {
        border-bottom: none !important;
      }
    }
  }
}
.approve-top {
  padding-top: 10px;
}
.examine-record {
  padding-top: 20px;
  border-top: #eee solid 1px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #409eff;
    margin-bottom: 10px;
  }
}
.tea-btn {
  &.el-button--primary {
    background-color: #5186c2;
    border-color: #5186c2;
  }
  &.is-disabled {
    opacity: 0.8;
  }
}
</style>



