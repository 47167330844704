
let baseURL = '/api'
const url = window.location.href
let imgUrl
let browserUrl // 退出登录
if (url.indexOf('online') !== -1) {
    imgUrl = 'static'
} else {
    imgUrl = '/static'
}
let socketUrl
  // 测试环境
  if( url.indexOf('test') !== -1){
    browserUrl = 'http://192.168.6.178/#/usercenter/login'
     // 生产环境
  } else {
    browserUrl = 'https://user.fefsfc.com/#/usercenter/login'
  }

export { imgUrl, baseURL, socketUrl, browserUrl }