import axios from './index'
import qs from 'qs'
import { baseApi } from '@/utils/index'
const baseURL = '8400/'
// *******************************************、
// 上传小组头像
export const uploadFile = params => {
    return axios.post(`/api/8100/fileUpload/policy`, params)
}
// ******************8100*************************、
// 获取常规班级
export const getClassListApi = params => {
    // return axios.post(`/api/8400/class/getTeacherClassPageList`, params)
    return axios.post(`/api/8810//textTeacherClassStatistics/getTeacherClassPageList`, params)
}
// 获取临时班列表
export const getTempClassListApi = params => {
    return axios.post(`/api/8710/classGroup/getTeacherClassTempPageList`, params)
}
// 获取临时班学生
export const getStudentLisTemporarytApi = params => {
    return axios.post(`/api/8400/classTemp/getStudentList`, params)
}
// 获取临时班学生（无分页）
export const getStudentRoutineOptions = params => {
    return axios.post(`/api/8400/classTemp/getStudentLists/{bjid}`, params)
}
// 获取常规版学生
export const getStudentRoutineList = params => {
    return axios.post(`/api/8400/class/getStudentList`, params)
}
// ********************8710***********************、
// -----------------分组管理
// 获取班级学生分组列表
export const studentGroupListApi = params => {
    return axios.post(`/api/8710/classGroup/selectClassGroupPage`, params)
}
// 获取历史学生分组列表
export const studentGroupHistoryListApi = params => {
    return axios.post(`/api/8710/classGroup/selectHistoryClassGroupPage`, params)
}
// 获取未选择学生
export const studentNoSelectApi = params => {
    return axios.post(`/api/8710/classGroup/selectClassStudent`, params)
}
// 创建班级小组
export const creatGroupAddApi = params => {
    return axios.post(`/api/8710/classGroup/insertClassGroup`, params)
}
// 修改班级小组
export const creatGroupEditApi = params => {
    return axios.post(`/api/8710/classGroup/updateClassGroup`, params)
}
// 随机创建班级小组
export const creatGroupGandomApi = params => {
    return axios.post(`/api/8710/classGroup/insertRandomClassGroup`, params)
}
// 查询班级小组
export const classGroupInforApi = params => {
    return axios.get(`/api/8710/classGroup/selectClassGroup`, params)
}
// 删除小组
export const classGroupDelApi = params => {
    return axios.get(`/api/8710/classGroup/deleteClassGroup`, params)
}
// 上课
export const startClassApi = params => {
    return axios.post(`/api/8710/classOnOff/classOn`, params)
}
// 下课
export const endClassApi = params => {
    return axios.post(`/api/8710/classOnOff/classOff`, params)
}
// ********************8500***********************、
// 在线人数
export const onLineApi = params => {
    return axios.get(`/api/8500/baseData/getAllOnlineCount`, params)
}
// **********************8400********************************
// 数据中心老师班级数和学生数量
export const ClassStudentNumApi = params => {
    return axios.get(`/api/8400/classTemp/getTeacherClassTemp`, params)
}
// 数据中心临时班级下拉
export const ClassTempOptionsApi = params => {
    return axios.get(`/api/8400/classTemp/getClassTemp`, params)
}
// ***********************8730*************************************** 
// 数据中心学习统计柱状图数据
export const dataStatisticsApi = params => {
    return axios.post(`/api/8810/textTeacherClassStatistics/getClassStudentXjAvg`, params)
}
// 
// ---------------------------临时班级（已经排集训）列表
export const classTempGradeListApi = params => {
    return axios.post(`/api/8810/textTeacherClassStatistics/getClassTempTj`, params)
}
// **************************************成绩数据
// 班级成绩
export const classTempStudentGradeListApi = params => {
    return axios.post(`/api/8810/textTeacherClassStatistics/getClassStudentScore`, params)
}

// 临时班学生成绩个人详情
export const classTempStudentGradeInforApi = params => {
    return axios.post(`/api/8810/textTeacherClassStatistics/getClassStudentScoreDetail`, params)
}
// 章下拉
export const wordEditionOptionsApi = params => {
    return axios.post(`/api/8400/applicationMenu/selectTextChapter`, params)
}
// 节下拉
export const wordChapterOptionsApi = params => {
    return axios.post(`/api/8400/applicationMenu/selectTextUnit`, params)
}
// *************************************错词列表
export const classTempWrongWordListApi = params => {
    return axios.post(`/api/8730/teacherClassStatistics/getClassErrorWordList`, params)
}
// *************************************金币数据
// 获取班级学生
export const studentGoldAllStudentApi = params => {
    return axios.get(`/api/8100/student/getTempClassAllStudents`, params)
}
// 学生金币列表
export const studentGoldListApi = params => {
    return axios.post(`/api/8810/studentCoin/getStudentCoinTotal`, params)
}
// 奖励金币
export const studentRewardGoldSaveApi = params => {
    return axios.post(`/api/8810/studentCoin/rewardCoin`, params)
}
// 扣除金币 
export const studentDeductGoldSaveApi = params => {
    return axios.post(`/api/8810/studentCoin/deductionCoin`, params)
}
// 查询该学生金币明细
export const getCoinsList = params => {
    // return axios.post(`/api/8720/studentCoinDetail/getStudentCoinDetailList`, params)
    return axios.post(`/api/8810/studentCoin/getStudentCoinDetail`, params)
}
// 班级错词列表
// teacherClassStatistics/getClassErrorWordList
//--------------------------------------------- 四个分屏
// 1全员排名
export const screenAllStudentRankingApi = params => {
    return axios.post(`/api/8730/teacherClassStatistics/stuRankInfoOfScreen1`, params)
}
// 2小组排名
export const screenGroupRankingApi = params => {
    return axios.post(`/api/8730/teacherClassStatistics/getClassGroupStudentSplitList`, params)
}
// 3学员进步排名
export const screenStudentProgressRankingApi = params => {
    return axios.post(`/api/8730/teacherClassStatistics/getStudentProgressSplitList`, params)
}
// 4错题排名
export const wrongQuestionRankingApi = params => {
    return axios.post(`/api/8730/teacherClassStatistics/getClassErrorWordSplitList`, params)
}
// ---------------------------------------------------------对比分析
// 班级下拉
export const analysisClassOptionsApi = params => {
    return axios.get(`/api/8100/class/selectTempClasss`, params)
}
// 集训下拉
// export const analysisTrainOptionsApi = params => {
//     return axios.get(`api/8100/class/selectTempTraining`, params)
// }
export const analysisTrainOptionsApi = params => {
    return axios.get(`/api/8730/conparison/selectTempTraining`, params)
}
// 小组下拉
export const analysisGroupOptionsApi = params => {
    return axios.get(`/api/8730/conparison/selectGroup`, params)
}
//学生下拉
export const analysisStudentOptionsApi = params => {
    return axios.get(`/api/8730//conparison/selectStudent`, params)
}
// 班级对比
export const analysisClassContrastApi = params => {
    return axios.post(`/api/8730/conparison/classConparisonDetail`, params)
}
// 小组对比
export const analysisGroupContrastApi = params => {
    return axios.post(`/api/8730/conparison/groupConparisonDetail`, params)
}
// 个人对比
export const analysisStudentContrastApi = params => {
    return axios.post(`/api/8730/conparison/stundyConparisonDetail`, params)
}
// 兑换审批-------------------------------------------------------------
// 兑换的物品下拉框选择
export const exchangeGoodsOptionsApi = params => {
    return axios.post(`/api/8700//exchangeItems/ExchangeItemsSelect`, params)
}
// 兑换审批列表
export const exchangeApproveListApi = params => {
    return axios.post(`/api/8700/itemExchange/selectItemExchangePage`, params)
}
// 保存审批/修改审批结果
export const exchangeApproveSaveApi = params => {
    return axios.post(`/api/8700/itemExchange/itemExchangeApprove`, params)
}

// 确定收货
export const exchangeConfirmReceiptApi = params => {
    return axios.post(`/api/8700/itemExchange/itemExchangeSendOrReceive`, params)
}


